<script setup>
import ViewportHelper from '~/components/viewport-helper.vue'

useHead({
  htmlAttrs: {
    class: "text-sm xl:text-base 2xl:text-xl",
    dir: "ltr",
    lang: "en"
  },
  bodyAttrs: {
    class: "antialiased bg-page text-default tracking-tight",
  }
})

useSeoMeta({
  title: 'TGI - Client Portal',
  description: 'Protect what matters most with TGI. More than just insurance, we offer you a peace of mind and financial security.',
})
</script>

<template>
  <div>
    <!-- Viewport Helper (development) -->
    <ViewportHelper />

    <!-- content -->
    <NuxtPage />
  </div>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  // tgi
  --tgi-font-sans: 'Inter';
  --tgi-font-serif: var(--tgi-font-sans);
  --tgi-font-heading: var(--tgi-font-sans);
  --tgi-color-primary: rgb(1 97 239);
  --tgi-color-secondary: rgb(1 84 207);
  --tgi-color-accent: rgb(109 40 217);
  --tgi-color-text-heading: rgb(0 0 0);
  --tgi-color-text-default: rgb(16 16 16);
  --tgi-color-text-muted: rgb(16 16 16 / 66%);
  --tgi-color-bg-page: rgb(255 255 255);
  --tgi-color-bg-page-dark: rgb(3 6 32);
  --tgi-color-bg-page-accent: rgb(251, 251, 251);

  // primevue (tailwind blue)
  --primary-50: 239 246 255;
  --primary-100: 219 234 254;
  --primary-200: 191 219 254;
  --primary-300: 147 197 253;
  --primary-400: 96 165 250;
  --primary-500: 59 130 246;
  --primary-600: 37 99 235;
  --primary-700: 29 78 216;
  --primary-800: 30 64 175;
  --primary-900: 30 58 138;
  --primary-950: 23 37 84;
  --surface-0: 255 255 255;
  --surface-50: 248 250 252;
  --surface-100: 241 245 249;
  --surface-200: 226 232 240;
  --surface-300: 203 213 225;
  --surface-400: 148 163 184;
  --surface-500: 100 116 139;
  --surface-600: 71 85 105;
  --surface-700: 45 55 72;
  --surface-800: 30 41 59;
  --surface-900: 15 23 42;
  --surface-950: 3 6 23;

  ::selection {
    background-color: lavender;
  }
}

@layer utilities {
  .bg-page {
    background-color: var(--tgi-color-bg-page);
  }

  .bg-dark {
    background-color: var(--tgi-color-bg-page-dark);
  }

  .bg-light {
    background-color: var(--tgi-color-bg-page);
  }

  .bg-lighter {
    background-color: var(--tgi-color-bg-page-accent);
  }

  .text-page {
    color: var(--tgi-color-text-page);
  }

  .text-muted {
    color: var(--tgi-color-text-muted);
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center rounded-full border-gray-400 border bg-transparent font-medium text-center text-base text-page leading-snug transition py-3.5 px-6 md:px-8 ease-in duration-200 focus:ring-blue-500 focus:ring-offset-blue-200 focus:ring-2 focus:ring-offset-2 hover:bg-gray-100 hover:border-gray-600 cursor-pointer;
  }

  .btn-primary {
    @apply btn font-semibold bg-primary text-white border-primary hover:bg-secondary hover:border-secondary hover:text-white;

    &.locked {
      @apply bg-gray-400 border-gray-400 text-gray-600 cursor-not-allowed;
    }
  }

  .btn-secondary {
    @apply btn;
  }

  .btn-light {
    @apply btn bg-white;
  }

  .btn-tertiary {
    @apply btn border-none shadow-none text-muted hover:text-gray-900;
  }
}

// apply shadow and rounding to all primevue panels
.p-panel {
  @apply shadow-md rounded-lg
}
</style>
